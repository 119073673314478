<template>
  <div />
</template>

<script>
import { settings } from "@/config";

export default {
  name: "HomeExternoVideos",

  data: function() {
    return {
      imageSiteHeader: settings.fastLogoPrincipal,
      whatsLink: "https://api.whatsapp.com/send?phone=" + settings.fastTelefoneContato + "&text=",
    };
  },
  mounted() {
    this.$store.state.fastCarregando = false;
  },
};
</script>

<style scoped>
.fdn-home-header {
  display: none;
}
body {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);
}
.fast-error-container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fast-error-container .fast-logo-error {
  display: block;
  margin: 20px auto;
  max-width: 50%;
}
.fast-error-container .error-template {
  padding: 40px 15px;
  text-align: center;
}
.fast-error-container .error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.fast-error-container .error-actions .btn {
  margin-right: 10px;
}
</style>
